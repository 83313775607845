import { Button, DateRangePicker } from '@capturi/ui-components'
import {
  Box,
  Divider,
  Flex,
  Grid,
  HStack,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useToast,
} from '@chakra-ui/react'
import { endOfDay, startOfDay, subMonths } from 'date-fns'
import React, { useState } from 'react'
import { MdUpdate } from 'react-icons/md'
import { useParams } from 'react-router'
import KeyTopicDiscoveryForm from './KeyTopicDiscovery'
import KeyTopicsDiscoveryLoading from './KeyTopicsDiscoveryLoading'
import {
  useKeyTopicsDiscoveries,
  useKeyTopicsDiscoveryStart,
} from './useKeyTopicsDiscovery'

const KeyTopics: React.FC = () => {
  const { uid: organizationUid } = useParams()
  const toast = useToast()
  const { data } = useKeyTopicsDiscoveries(organizationUid)
  const [activeDiscovery, setActiveDiscovery] = useState<string | undefined>('')
  const { mutate: startKeyTopicsDiscovery, isPending } =
    useKeyTopicsDiscoveryStart()
  const [toggleDatepicker, setToggleDatepicker] = useState<boolean>(false)

  const [date, setDate] = useState({
    from: startOfDay(subMonths(new Date(), 3)),
    to: endOfDay(new Date()),
  })

  const handleStartDiscovery = (): void => {
    startKeyTopicsDiscovery(
      {
        organizationUid: organizationUid,
        fromDate: date.from,
        toDate: date.to,
      },
      {
        onSuccess: () => {
          toast({ title: 'Discovery started', status: 'info' })
        },
        onError: (error) => {
          toast({
            title: 'Initiation of discovery failed',
            description: `${error.message}`,
            status: 'error',
          })
        },
      },
    )
  }
  if (activeDiscovery === '' && data) {
    const active = data.find((d) => d.isProd)
    setActiveDiscovery(active?.discoveryUid)
  }
  return (
    <>
      <Flex justify="space-between" mb={4}>
        <Heading mb={3} fontSize="18px" fontWeight="medium">
          Key topics
        </Heading>
        <Flex gap={2}>
          <Popover placement="bottom-end">
            {({ onClose }) => (
              <>
                <PopoverTrigger>
                  <Button
                    aria-label={'Button to replay webhook events'}
                    leftIcon={<MdUpdate />}
                    textAlign="right"
                    onClick={() => setToggleDatepicker(!toggleDatepicker)}
                  >
                    {date.from.toDateString()} - {date.to.toDateString()}
                  </Button>
                </PopoverTrigger>
                <PopoverContent width="100%">
                  <span>
                    <PopoverArrow />
                  </span>
                  <PopoverBody px={0} pt={4}>
                    <Flex flexDir="column" alignItems="flex-start" gap={2}>
                      <Box>
                        <DateRangePicker
                          value={date}
                          showInputs
                          placeholderFrom={'From'}
                          placeholderTo={'To'}
                          onSelectDateRange={setDate}
                        />
                      </Box>
                      <Divider />
                      <Flex
                        justifyContent="flex-end"
                        width="100%"
                        px={4}
                        gap={2}
                      >
                        <Button onClick={onClose}>Cancel</Button>
                        <Button
                          colorScheme="primary"
                          onClick={() => {
                            onClose()
                          }}
                        >
                          Apply
                        </Button>
                      </Flex>
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
          <Button
            onClick={() => handleStartDiscovery()}
            variant="ghost"
            color="primary.500"
            isLoading={isPending}
          >
            Run new discovery
          </Button>
        </Flex>
      </Flex>
      <Grid templateColumns="repeat(1, 1fr)" columnGap={3}>
        {data?.map((discovery, index) => (
          <Box
            key={index}
            mb={3}
            border="1px solid"
            borderColor="gray.400"
            borderRadius="12px"
            p={3}
          >
            {discovery.status === 'Success' ? (
              <KeyTopicDiscoveryForm
                key={index}
                props={discovery}
                setActiveDiscovery={setActiveDiscovery}
                activeDiscovery={activeDiscovery}
              />
            ) : (
              <HStack justify="center">
                <KeyTopicsDiscoveryLoading
                  discoveryUid={discovery.discoveryUid}
                  organizationUid={organizationUid}
                />
              </HStack>
            )}
          </Box>
        ))}
      </Grid>
    </>
  )
}

export default KeyTopics
